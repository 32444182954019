/// <reference path="o365.pwa.declaration.sw.plugins.CdnCacheKey.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { CacheKeyWillBeUsedCallbackParam } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    class CdnCacheKey {
        public async cacheKeyWillBeUsed({ request }: CacheKeyWillBeUsedCallbackParam): Promise<string | Request> {
            const cdnUrl = self.o365.cdnUrl

            const cdnUrlRegex = new RegExp(`${cdnUrl}/(.*)`);

            const cdnUrlRegexMatch = request.url.match(cdnUrlRegex);

            if (cdnUrlRegexMatch) {
                const path = cdnUrlRegexMatch[1];

                return path;
            }

            const hostUrlCdnRegex = new RegExp('(?:/nt/)?cdn/(.*)');
            const hostUrlCdnRegexMatch = request.url.match(hostUrlCdnRegex);

            if (hostUrlCdnRegexMatch) {
                const path = hostUrlCdnRegexMatch[1];

                return path;
            }

            return request;
        }
    }
    
    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.plugins.CdnCacheKey.d.ts')>({ CdnCacheKey });
})();